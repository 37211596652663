<template>
  <forget-form />
</template>

<script>
import forgetForm from '../../components/moleculs/forgetForm.vue';

export default {
  name: 'restore-access',
  components: {
    forgetForm,
  },
};
</script>

<style lang="scss" scoped>

</style>
