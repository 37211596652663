<template>
  <div style="background-color: #1e2d3e; height: 100%">
    <ValidationObserver ref="observerForgotForm">
      <b-form
        class="form-frg"
        @submit.prevent="submitForgotBtn()"
        v-if="!succesComputedValue"
      >
        <b-form-group class="form-frg-inp-wrp text-center">
          <p>
            {{ $t('forgot password', currentSelectedLanguage) }}
          </p>
        </b-form-group>
        <input-form
          class="form-frg-inp-wrp"
          input-rule="required|email"
          name-input="Email"
          input-type="text"
          :classArray="['form-frg-inp-wrp--nested']"
          :placeholderText="$t('email', currentSelectedLanguage)"
          @keyUpInputEvent="setEmailForgetValue"
          :stateProps="error.email.condition"
        />

        <b-form-group class="form-frg-btn-wrp">
          <sbmt-btn :buttonText="$t('Send Login Details', currentSelectedLanguage)" />
        </b-form-group>

        <b-form-group class="form-frg-link-wrp text-center">
          <b-link to="/">
            {{ $t('go back', currentSelectedLanguage) }}
          </b-link>
        </b-form-group>

        <b-form-invalid-feedback
          class="text-center"
          :state="errorMessage"
        >
          {{ this.error.email.message }}
        </b-form-invalid-feedback>
      </b-form>
      <div  class="form-frg" v-else>
        <p class="text-center notification-text">
          {{ `${$t('ForgetPasswordNotificationText', currentSelectedLanguage)}` }}
          <a :href="`mailto:${customerEmail}`">
            {{ `${customerEmail}` }}
          </a>
        </p>

        <b-form-group class="form-frg-btn-wrp">
          <sbmt-btn
            @buttonEventEmit="goBack()"
            :buttonText="$t('go back', currentSelectedLanguage)"
          />
        </b-form-group>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import sbmtBtn from '../atoms/button.vue';
import inputForm from '../atoms/input.vue';
import { FORGET_PASSWORD, PURGE_ERROR } from '../../store/modules/auth.module';
import { SET_LOADER } from '../../store';
import localVariable from '../../core/helpers/variables/localVariables';

export default {
  name: 'forgetForm',
  components: {
    ValidationObserver,
    sbmtBtn,
    inputForm,
  },
  data() {
    return {
      formPwdForget: {
        email: null,
        success: false,
      },
      error: {
        email: {
          message: null,
          condition: false,
        },
      },
    };
  },
  computed: {
    succesComputedValue() {
      return this.formPwdForget.success;
    },
    customerEmail() {
      return process.env.VUE_APP_SUPPORT_EMAIL;
    },
    ...mapState(
      {
        errors: (state) => state.auth.errors,
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    errorMessage() {
      return !this.error.email.condition;
    },
  },
  methods: {
    async submitForgotBtn() {
      await this.$store.dispatch(PURGE_ERROR);
      const isValid = await this.$refs.observerForgotForm.validate();
      this.error.email.message = this.$refs.observerForgotForm.errors.Email.length > 0
        ? this.$refs.observerForgotForm.errors.Email[0] : '';
      this.error.email.condition = this.$refs.observerForgotForm.errors.Email.length > 0;
      if (isValid) {
        this.sendForgetCreditianals();
      }
    },
    sendForgetCreditianals() {
      const self = this;
      this.$store.dispatch(SET_LOADER, localVariable.LOADER.LOADER_SET_VALUE);
      this.$store.dispatch(FORGET_PASSWORD, this.formPwdForget).then(() => {
        this.$store.dispatch(SET_LOADER, localVariable.LOADER.LOADER_REMOVE_VALUE);
        self.formPwdForget.success = true;
      });
    },
    setEmailForgetValue(value) {
      this.formPwdForget.email = value;
    },
    setInitialData() {
      this.formPwdForget.email = null;
      this.formPwdForget.success = false;
    },
    goBack() {
      this.$router.push({ name: 'Login' });
    },
  },
  beforeDestroy() {
    this.setInitialData();
    this.$store.dispatch(PURGE_ERROR);
  },
};
</script>

<style lang="scss">
.form-frg {
  max-width: 305px;
  padding-top: 218px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;

  &-inp-wrp {
    &:not(:first-child) {
      display: block;
      margin-bottom: 15px;
    }
    &:first-child {
      p {
        @include font-source($openSans, 18px, $white, normal, normal, normal);
        @include space(margin, bottom, 30px);
      }
    }

    /* Nested inputs props class */
    .form-frg-inp-wrp--nested {
      input {
        background-color: rgba(56, 68, 84, 0.6);
        color: $white;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $white;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $white;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $white;
        }
      }
    }
  }

  &-btn-wrp {
    button {
      width: 100%;
      text-transform: uppercase;
    }
  }

  &-link-wrp {
    a {
      @include font-source($openSans, 11px, $white, normal, 1.55px, normal);

      &:hover, &:focus {
        color: $white;
        text-decoration: $none;
      }
    }
  }
}
.notification-text {
  @include font-source($openSans, 16px, $white, normal, normal, normal);
  @include space(margin, bottom, 46px);
}
</style>
